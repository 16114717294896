import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Box, Grid, Stack, Typography,
} from '@mui/material';

import ServiceButtonWithToast from './ServiceButtonWithToast';
import ButtonWithModal from '../../components/ButtonWithModal';
import ConditionCreateSubForm from '../Condition/ConditionCreateSubForm';
import Form from '../../components/useForm';
import ConditionEditForm from '../Condition/ConditionEditForm';

export default function ExclusionCriteriaSubForm(props) {
  const {
    onSubmit, exclusionCriteria, refreshParent,
  } = props;

  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    exclusionCriteria ? (
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row">
            <Typography variant="h6" gutterBottom>
              Exclusion Criteria
            </Typography>
            <ServiceButtonWithToast
              buttonText="Delete"
              service={() => onSubmit(null)}
              onSuccess={refreshParent}
              successMsg="Deleted"
              size="small"
            />
          </Stack>
        </Grid>

        <Grid item xs={12} sx={{ ml: 2 }}>
          <ConditionEditForm
            condition={exclusionCriteria}
            onSubmit={(condition) => onSubmit(condition)}
            onSuccess={refreshParent}
            successMsg="Exclusion criteria updated."
          />
        </Grid>
        <Grid item xs={4} />
      </Grid>

    ) : (
      <ButtonWithModal
        buttonText="Add exclusion criteria"
        modalTitle="Add exclusion criteria"
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
      >
        <Box sx={{ maxWidth: 400 }}>
          <Form>
            <ConditionCreateSubForm
              onSubmit={
                (condition) => onSubmit(condition)
              }
              onSuccess={() => {
                refreshParent();
                setModalIsOpen(false);
              }}
              successMsg="Added"
            />
          </Form>
        </Box>
      </ButtonWithModal>
    )

  );
}

ExclusionCriteriaSubForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  exclusionCriteria: PropTypes.shape({
    id: PropTypes.number,
    conditionType: PropTypes.string,
    subconditions: PropTypes.arrayOf(PropTypes.shape({ conditionType: PropTypes.string })),
  }),
  refreshParent: PropTypes.func.isRequired,
};

ExclusionCriteriaSubForm.defaultProps = {
  exclusionCriteria: null,
};
