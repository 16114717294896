import PropTypes from 'prop-types';

const basicServiceHandler = (service, successCB, failCB, finalCB) => {
  service() // ensure service returns a promise eg () => ... or () => {return ...}, not () => {...}
    .then((result) => {
      if (!result) return;
      if (result.status === 200 || result.status === 201) {
        successCB(result);
      } else {
        failCB(result);
      }
    }).catch((error) => {
      failCB(error);
    }).finally(() => {
      if (finalCB) {
        finalCB();
      }
    });
};

export const promiseServiceHandler = (
  service,
  successCB,
  failCB,
  finalCB,
) => new Promise((resolve, reject) => {
  let resolveOrReject;
  basicServiceHandler(
    () => service(),
    (response) => {
      successCB(response);
      resolveOrReject = resolve;
    },
    (error) => {
      failCB(error);
      resolveOrReject = reject;
    },
    () => {
      finalCB();
      resolveOrReject();
    },
  );
});

export default basicServiceHandler;

export const serviceCallbackPropTypes = PropTypes.shape({
  service: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  errorMsg: PropTypes.string,
});
