import React from 'react';
import PropTypes from 'prop-types';

import MedicalContentTreeItem, { useMedicalContentTreeItem } from '../_common/MedicalContentTreeItem';
import { useApiClient } from '../../context/ApiClientContext';
import IntakePlanTreeItem from './IntakePlanTreeItem';
import IntakePlanCreateButtonWithModal from './IntakePlanCreateButtonWithModal';

export default function IntakePlanParentTreeItem(props) {
  const {
    setFormPane,
    setTreeManagementServices,
  } = props;

  const apiClient = useApiClient();

  const {
    MCTreeItemLabel,
    MCTreeItemChildNodes,
    expand,
    setExpand,
    setMCTreeItemChildNodes,
  } = useMedicalContentTreeItem('Intake Plans');

  const fetchChildNodes = async () => {
    const results = await apiClient.getIntakePlans();
    const treeItems = results.data.map((result) => (
      <IntakePlanTreeItem
        key={`${result.uniqueName}_${result.id}`}
        intakePlanId={result.id}
        intakePlanReferenceCode={result.referenceCode}
        moduleId={result.moduleId}
        moduleName={result.moduleName}
        translationLocales={result.translationLocales}
        setFormPane={setFormPane}
        setTreeManagementServices={setTreeManagementServices}
        refreshParent={fetchChildNodes}
      />
    ));

    setMCTreeItemChildNodes(treeItems);
  };

  const reloadAndExpandNode = () => {
    fetchChildNodes();
    setExpand(true);
  };

  const onSelect = () => {
    setFormPane(
      <IntakePlanCreateButtonWithModal onCreateSuccess={reloadAndExpandNode} />,
    );
    setTreeManagementServices({});
  };

  return (
    <MedicalContentTreeItem
      id={0}
      MCTreeItemLabel={MCTreeItemLabel}
      MCTreeItemChildNodes={MCTreeItemChildNodes}
      fetchChildNodes={fetchChildNodes}
      onSelect={onSelect}
      expand={expand}
      textToDisplayIfNoChildren="No intake plans yet..."
    />
  );
}

IntakePlanParentTreeItem.propTypes = {
  setFormPane: PropTypes.func.isRequired,
  setTreeManagementServices: PropTypes.func.isRequired,
};
