import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Divider, Typography } from '@mui/material';
import MedicalContentTreeItem, { useMedicalContentTreeItem } from '../_common/MedicalContentTreeItem';
import ReasonForVisitPane from './ReasonForVisitPane';
import ActionAdditionForm from '../Actions/ActionAdditionForm';
import renderActionList from '../Actions/renderActionList';
import { useApiClient } from '../../context/ApiClientContext';

export default function ReasonForVisitTreeItem(props) {
  const {
    reasonForVisitId,
    reasonForVisit,
    actionListId,
    label,
    setFormPane,
    setTreeManagementServices,
    refreshParent,
    onDeleteWarningMessage,
    onDeleteConfirmed,
  } = props;

  const apiClient = useApiClient();

  const {
    MCTreeItemLabel,
    MCTreeItemChildNodes,
    expand,
    setExpand,
    setMCTreeItemChildNodes,
  } = useMedicalContentTreeItem(label);

  const fetchChildNodes = async () => {
    setMCTreeItemChildNodes(
      await renderActionList(
        apiClient,
        actionListId,
        setFormPane,
        setTreeManagementServices,
        fetchChildNodes,
      ),
    );
  };

  const reloadAndExpandNode = () => {
    fetchChildNodes();
    setExpand(true);
  };

  const onSelect = () => {
    setFormPane(
      <>
        <ReasonForVisitPane
          reasonForVisitId={reasonForVisitId}
          initialFieldValues={reasonForVisit}
          refreshParent={refreshParent}
          label={MCTreeItemLabel}
        />
        <Divider />
        <Typography variant="h6" gutterBottom>
          Add Actions
        </Typography>
        <ActionAdditionForm
          actionListId={actionListId}
          reloadCallback={reloadAndExpandNode}
        />
      </>,
    );
    setTreeManagementServices({
      onDeleteWarningMessage,
      deleteService: onDeleteConfirmed,
      onSuccess: refreshParent,
    });
  };

  useEffect(() => {
    fetchChildNodes();
  }, []);

  return (
    <MedicalContentTreeItem
      id={reasonForVisitId}
      MCTreeItemLabel={MCTreeItemLabel}
      MCTreeItemChildNodes={MCTreeItemChildNodes}
      isEndNode={MCTreeItemChildNodes && MCTreeItemChildNodes.length === 0}
      fetchChildNodes={fetchChildNodes}
      onSelect={onSelect}
      expand={expand}
    />
  );
}

ReasonForVisitTreeItem.propTypes = {
  reasonForVisitId: PropTypes.number.isRequired,
  reasonForVisit: PropTypes.shape({
    c2iName: PropTypes.string,
    layName: PropTypes.string,
    nlpClassName: PropTypes.string,
  }).isRequired,
  actionListId: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  setFormPane: PropTypes.func.isRequired,
  refreshParent: PropTypes.func.isRequired,
  onDeleteWarningMessage: PropTypes.string.isRequired,
  onDeleteConfirmed: PropTypes.func.isRequired,
  setTreeManagementServices: PropTypes.func.isRequired,
};
