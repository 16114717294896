import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { useApiClient } from '../../../context/ApiClientContext';
import ButtonWithModal from '../../../components/ButtonWithModal';
import ConditionCreateSubForm from '../ConditionCreateSubForm';
import Form from '../../../components/useForm';
// eslint-disable-next-line import/no-cycle
import SubconditionForm from './SubconditionForm';
import ServiceButtonWithToast from '../../_common/ServiceButtonWithToast';

export default function AnyOrAllOfConditionEditForm(props) {
  const {
    conditionId,
    conditionType,
    subconditions,
    onSuccess,
  } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const apiClient = useApiClient();

  return (
    <Grid>
      <Typography variant="h6">
        {conditionType === 'anyOf' ? 'OR (Any of the following):' : 'AND (All of the following):'}
      </Typography>

      {subconditions.length ? subconditions.map((subcondition) => (
        <SubconditionForm
          key={subcondition.id}
          conditionId={conditionId}
          subcondition={subcondition}
          onSuccess={onSuccess}
        />
      )) : (
        <Typography variant="subtitle1" sx={{ ml: 2 }}>
          No subconditions added yet
        </Typography>
      )}
      <ButtonWithModal
        buttonText="Add Subcondition"
        size="medium"
        modalTitle="Add Subcondition"
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
      >
        <Form>
          <ConditionCreateSubForm
            onSubmit={(newCondition) => apiClient.addSubcondition(conditionId, newCondition)}
            onSuccess={() => {
              setModalIsOpen(false);
              onSuccess();
            }}
            successMsg="Subcondition added"
          />
        </Form>
      </ButtonWithModal>
      <ServiceButtonWithToast
        disabled={!subconditions.length}
        buttonText="Remove all"
        service={() => apiClient.removeAllSubconditions(conditionId)}
        onSuccess={onSuccess}
        successMsg="Removed"
      />
    </Grid>
  );
}

AnyOrAllOfConditionEditForm.propTypes = {
  conditionId: PropTypes.number.isRequired,
  conditionType: PropTypes.oneOf(['anyOf', 'allOf']).isRequired,
  subconditions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    conditionType: PropTypes.string,
    subconditions: PropTypes.arrayOf(PropTypes.shape({ conditionType: PropTypes.string })),
  })).isRequired,
  onSuccess: PropTypes.func.isRequired,
};
