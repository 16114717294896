import React from 'react';
import PropTypes from 'prop-types';

import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';

export default function TranslationIntakePlanLocalesTable({ intakePlansByLocale }) {
  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell style={{ fontWeight: 'bold' }}>Locale</TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>Intake Plans (Ref Code: Assigned Module Name)</TableCell>
          </TableRow>
          {Object.entries(intakePlansByLocale).map(([locale, intakePlans]) => (
            <TableRow key={locale}>
              <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>
                {locale.toUpperCase()}
              </TableCell>
              <TableCell>
                {intakePlans.map((intakePlan) => (
                  <div key={intakePlan.referenceCode}>{`${intakePlan.referenceCode}: ${intakePlan.moduleName}`}</div>
                ))}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

TranslationIntakePlanLocalesTable.propTypes = {
  intakePlansByLocale: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.shape({
      referenceCode: PropTypes.string,
      moduleName: PropTypes.string,
    })),
  ).isRequired,
};
