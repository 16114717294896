import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Dropdown from '../../components/controls/Dropdown';
import AttributeSelector from '../Attribute/attribute/findExisting/AttributeSelector';
import ServiceButtonWithToast from '../_common/ServiceButtonWithToast';

export default function ScoreFactorNumericAttributeForm(props) {
  const {
    onSubmit, onSuccess,
  } = props;
  const [operator, setOperator] = useState(null);
  const [attributeId, setAttributeId] = useState(null);
  return (
    <>
      <Dropdown
        value={operator}
        name="operator"
        onChange={((e) => setOperator(e.target.value))}
        label="Select an Operator"
        options={[
          { id: 'add', name: 'Add' },
          { id: 'subtract', name: 'Subtract' },
          { id: 'multiply', name: 'Multiply' },
          { id: 'divide', name: 'Divide' },
          { id: 'bmiDivide', name: 'BMI Divide' },
        ]}
      />
      <AttributeSelector
        requiredSubtype="numeric"
        selectedContextType="patient"
        selectedAttributeId={attributeId}
        setSelectedAttributeId={setAttributeId}
      />
      <ServiceButtonWithToast
        disabled={!(operator && attributeId)}
        buttonText="Submit"
        service={() => onSubmit({ attributeId, operator })}
        successMsg="Factor added"
        onSuccess={onSuccess}
      />
    </>
  );
}

ScoreFactorNumericAttributeForm.propTypes = {
  initialFieldValues: PropTypes.shape({
    points: PropTypes.number,
  }),
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

ScoreFactorNumericAttributeForm.defaultProps = {
  initialFieldValues: { points: 0 },
};
