import React, { useState, useEffect } from 'react';
import {
  Grid, Box, Typography,
} from '@mui/material';

import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';

import { useApiClient, convertAxiosErrorToUserFacingMessage } from '../../context/ApiClientContext';
import { Controls } from '../../components/controls/Controls';
import Form, { useForm } from '../../components/useForm';
import useToast from '../../hooks/useToast';
import basicServiceHandler from '../../services/basicServiceHandler';
import ModuleSaveAsNewButton from './ModuleSaveAsNewButton';

const initialFieldValues = {
  moduleName: null,
  languagesToTranslate: [],
};

export default function ModuleEditForm(props) {
  const { id, updateCallback, refreshParent } = props;
  const [loading, setLoading] = useState(false);

  const apiClient = useApiClient();

  const {
    values,
    setValues,
    handleInputChange,
  } = useForm(initialFieldValues);

  const {
    toastSetSuccessNotification,
    toastSetErrorNotification,
  } = useToast();

  const isLoaded = () => Object.values(values).every((item) => item !== null);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    basicServiceHandler(
      () => apiClient.updateModule(
        id,
        values,
      ),
      () => {
        updateCallback(values.moduleName);
        toastSetSuccessNotification('Update successful!');
      },
      (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
      () => setLoading(false),
    );
  };

  useEffect(() => {
    apiClient.getModule(id).then((result) => {
      const {
        uniqueName, isRepeatable,
      } = result.data;
      setValues({
        moduleName: uniqueName, isRepeatable,
      });
    }).catch((error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)));
  }, [id]);

  return (
    !isLoaded()
      ? (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      )
      : (
        <Grid container>
          <Grid item xs={6}>
            <Form onSubmit={handleSubmit}>
              <Controls.Input
                label="Module Name"
                name="moduleName"
                value={values.moduleName}
                onChange={handleInputChange}
              />
              {values.isRepeatable && <Typography align="right">Repeatable</Typography>}
              <Grid container justifyContent="flex-end">
                <Controls.Button
                  variant="contained"
                  type="submit"
                  sx={{ margin: '6px' }}
                  loading={loading}
                  text="Save"
                  size="medium"
                />
              </Grid>
            </Form>
          </Grid>

          <Grid container>
            <ModuleSaveAsNewButton
              sourceModuleId={id}
              onSuccess={() => {
                toastSetSuccessNotification('New module created (see bottom of modules list)');
                refreshParent();
              }}
              onError={(error) => (
                toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error))
              )}
            />
          </Grid>
        </Grid>
      )
  );
}

ModuleEditForm.propTypes = {
  id: PropTypes.number.isRequired,
  updateCallback: PropTypes.func,
  refreshParent: PropTypes.func,
};

ModuleEditForm.defaultProps = {
  updateCallback: null,
  refreshParent: null,
};
