import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Typography,
} from '@mui/material';
import ReasonForVisitForm from './ReasonForVisitForm';
import { convertAxiosErrorToUserFacingMessage, useApiClient } from '../../context/ApiClientContext';
import useToast from '../../hooks/useToast';
import { useForm } from '../../components/useForm';
import ExclusionCriteriaSubForm from '../_common/ExclusionCriteriaSubForm';
import basicServiceHandler from '../../services/basicServiceHandler';

function ReasonForVisitPane({
  reasonForVisitId, initialFieldValues, initialExclusionCriteria, refreshParent,
}) {
  const apiClient = useApiClient();
  const { toastSetErrorNotification } = useToast();
  const [exclusionCriteria, setExclusionCriteria] = useState(initialExclusionCriteria);
  const {
    values,
    setValues,
    handleInputChange,
  } = useForm(initialFieldValues);

  const refresh = () => basicServiceHandler(
    () => apiClient.getReasonForVisit(reasonForVisitId),
    (result) => {
      const {
        c2iName, nlpClassName, layName, exclusionCriteria: newExclusionCriteria,

      } = result.data;
      setValues({ c2iName, nlpClassName, layName });
      setExclusionCriteria(newExclusionCriteria);
    },
    (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
    () => {},
  );
  useEffect(refresh, [reasonForVisitId]);

  const handleUpdate = (reasonForVisitData) => {
    const reasonForVisitUpdateRequest = {
      c2iName: reasonForVisitData.c2iName,
      layName: reasonForVisitData.layName,
      nlpClassName: reasonForVisitData.nlpClassName,
    };
    return apiClient.updateReasonForVisit(reasonForVisitId, reasonForVisitUpdateRequest);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Reason For Visit
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ReasonForVisitForm
          values={values}
          handleInputChange={handleInputChange}
          onSubmit={handleUpdate}
          onSuccess={refreshParent}
          onFail={toastSetErrorNotification}
        />
        <ExclusionCriteriaSubForm
          onSubmit={(condition) => apiClient.updateReasonForVisit(
            reasonForVisitId,
            { exclusionCriteria: condition },
          )}
          exclusionCriteria={exclusionCriteria}
          refreshParent={() => {
            refresh();
            refreshParent();
          }}
        />
      </Grid>
    </Grid>
  );
}

ReasonForVisitPane.propTypes = {
  reasonForVisitId: PropTypes.number.isRequired,
  initialFieldValues: PropTypes.shape({
    c2iName: PropTypes.string,
    layName: PropTypes.string,
    nlpClassName: PropTypes.string,
  }).isRequired,
  initialExclusionCriteria: PropTypes.shape({
    id: PropTypes.number,
    conditionType: PropTypes.string,
    subconditions: PropTypes.arrayOf(PropTypes.shape({ conditionType: PropTypes.string })),
  }),
  refreshParent: PropTypes.func.isRequired,
};

ReasonForVisitPane.defaultProps = {
  initialExclusionCriteria: null,
};

export default ReasonForVisitPane;
